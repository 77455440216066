/* Main --------------------------------------- */
.lcs_slide_out-r header a {
  left: 100px !important;
  box-shadow: none !important;
}

.hours {
  clear: both;
  line-height: normal;
  margin-right: -45px;
  margin-left: -45px;
  background: #660000 none;
}

.hours-full {
  background: #660000 none;
  padding: 30px 0;
}

.lib-hours-full {
  width: 375px;
  margin: 0 auto;
}

.lib-hours {
  box-sizing: border-box;
  width: 96%;
  margin: 0 auto;
  display: flex;
  background: #660000;
  padding: 30px 0 30px 60px;
}

.hours-select {
  width: 40%;
  height: 43px;
  margin-right: 140px;
}

.lib-hours-loc {
  box-sizing: border-box;
  border: none;
  height: 43px;
  width: 100%;
  padding: 10px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url(//lib.vt.edu/content/dam/lib_vt_edu/point-down.png) no-repeat right center;
}

.lib-hours-loc option {
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  height: 45px;
  padding: 14px 15px;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.lib-hours-hours {
  color: #fff;
  width: 325px;
}

.today {
  font-size: 20px;
  margin-right: 8px;
  float: left;
}

.table-responsive {
  float: left;
  border: none;
  margin-bottom: 0;
}

.hours-today {
  margin-bottom: 0;
}

.hours-today > tbody > tr > .hours-col-time {
  border: none;
  line-height: inherit;
  padding: inherit;
  vertical-align: baseline;
}

.hours-col-time {
  font-size: 20px;
  //-webkit-animation-name: hrs; /* Safari 4.0 - 8.0 */
  //-webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  //-moz-animation-name: hrs;
  //-moz-animation-duration: 2s;
  animation-name: hrs;
  animation-duration: 2s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes hrs {
  from {
    color: #fe5b00;
  }
  to {
    color: #fff;
  }
}

/* Standard syntax */
@keyframes hrs {
  from {
    color: #fe5b00;
  }
  to {
    color: #fff;
  }
}

.hours-link {
  display: block;
  clear: both;
}

.hours-link::after {
  content: " ▸";
}

.hours-link > a {
  color: #c6bfbb;
  font-size: 14px;
}

.nav-tabs > li::before {
  content: none !important;
}

.nav-tabs > li > a {
  margin-bottom: 0;
}

.day {
  width: 150px;
  display: inline-block;
}

.hours-tabs > .hours-list > .tab-content {
  background: #fff none;
  border: 1px solid #d5d5d5;
}

.hours-list > .nav-tabs > li {
  margin: 0 3px -1px 0;
  border: 1px solid #d5d5d5;
}

.hours-list > .nav-tabs > li.active > a {
  padding: 11px 15px;
  color: #fff;
  background-color: #660000;
}

.instagram {
  margin-right: -45px;
  margin-left: -45px;
  margin-bottom: 0;
}

.instagram-link {
  background: #557082 none;
  color: #fff;
  padding: 18px 45px 0 45px;
}

.instagram-link > h2 {
  margin-bottom: 0;
  padding-bottom: 24px;
  width: 250px;
}

h3 > a.initiatives-link {
  color: #fff;
}

.instagram-link > h3 > a {
  color: #fff;
}

.instagram-img {
  margin-right: -45px;
  margin-left: -45px;
  margin-bottom: 0;
}

.instagram-posts {
  background: #557082;
  padding: 0 45px 60px 45px;
}

.contact-us-info {
  font-size: 13px;
}

/* Responsive Media --------------------------- */
@media all and (max-width: 470px) {
  .hours-list > .tab-content > div > p {
    font-size: 14px;
  }
  .day {
    width: 95px;
  }
  .tab-content > .active {
    padding: 15px 10px;
  }
}

@media all and (max-width: 767px) {
  .lib-hours {
    display: block;
    padding: 10px 10px;
    width: 100%;
  }
  .lib-hours-hours {
    margin-top: 12px;
  }
  .today, .hours-col-time {
    font-size: 16px;
  }
  .hours-select {
    width: 100%;
  }
  .lcs_slide_out-l {
    display: none;
  }
  .panelcontent nav.flypanels-treemenu > .vt_nav_item_01 {
    display: none;
  }
  .lib-hours-full {
    width: 255px;
    margin: 0 auto;
  }
}

@media all and (min-width: 768px) {
  .hours-list > .tab-content > div > p {
    font-size: 14px;
  }
  .day {
    width: 105px;
  }
  .tab-content > .active {
    padding: 15px 10px;
  }
}

@media all and (min-width: 992px) {
  .hours-list > .tab-content > div > p {
    font-size: 18px;
  }
  .day {
    width: 150px;
  }
}

@media all and (min-width: 1200px) {
  .lcs_slide_out-l header a {
    font-size: 22px;
    padding: 20px;
  }
}
