/* Bento Box ---------------------------------- */

.bento-results {
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.bento-title {
  border-bottom: 1px dotted $vt-maroon;
  margin-bottom: 24px;
}

.bento-title h2, .bento-title a {
  color: $vt-maroon;
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 400;
  padding-top: .5em;
  &:hover {
    text-decoration: none;
  }
}

.bento-subtitle, .bento-subtitle a {
  font-size: 0.95em;
  color: $bento-text-color;
  font-weight: normal;
}

.bento-title div {
  font-size: 0.95em;
  color: $bento-text-color;
  font-weight: normal;
}


.bento-subtitle {
  line-height: 1.55;
  font-weight: 300;
  font-size: 15px;
}

.bento-record {
  padding-bottom: 3rem;
  &::after {
    display: block;
    content: "";
    clear: both;
  }

  .bento-record-doi {
    a {
    }
  }
}

.bento-record, .bento-record a {
  line-height: 1.55;
  font-weight: 400;
  font-size: 13px;
}

.bento-record .bento-record-title > a {
  line-height: 1.55;
  font-weight: 400;
  font-size: 18px;
}

.bento-results-total {
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px dotted $vt-maroon;
  margin-bottom: 2em;
}

.bento-results-total > a {
  line-height: 1.55;
  font-weight: 300;
  font-size: 15px;
}

//.bento-record > .image {
//  height: 120px;
//  width: 75px;
//  position: absolute;
//  top: 0;
//  right: 0;
//}

.volume, .publication, .issue, .page {
  display: inline-flex;
  float: left;
  margin-right: 5px;
}

#single-search {
  font-weight: 300;
  .form-control {
    border: 1px solid $vt-maroon;
  }
  .btn-maroon {
    color: #fff;
    background-color: $vt-maroon-200;
    border-color: $vt-maroon-300;
    &:hover {
      color: #fff;
      background-color: $vt-maroon-300;
      border-color: $vt-maroon-400;
    }
    &:focus {
      color: #fff;
      background-color: $vt-maroon-400;
      border-color: $vt-maroon-500;
    }
    &:active {
      color: #fff;
      background-color: $vt-maroon-300;
      border-color: $vt-maroon-400;
    }
  }
}

.bento-record-altmetric {
  padding: 5px;
  a {
    margin: 0;
  }
}

#bento-nav {
  .navbar-header {
    .navbar-toggle {
      float: left;
      .icon-bar {
        background-color: $vt-maroon-300;
      }
    }
  }
  .nav-pills.bento-nav {
    > li {
      > a {
        font-size: 13px;
        font-weight: 300;
        border-bottom: none;
        &:hover {
          color: $vt-orange;
          border-bottom: none;
          background: $white;
          border-color: $white;
        }
      }
      &:before {
        display: none;
      }
    }
  }
}

label.element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.search-wrapper {
  display: inline-flex;
  width: 100%;
  border: 2px solid #555;
  border-radius: 2px;
}

input.form-submit {
  font-size: 1.0em;
  border: none;
  width: 120px;
  height: 50px;
  padding: 10px;
  background: $vt-maroon none repeat scroll 0 0;
  cursor: pointer;
  color: $white;
  margin-right: 0;
  float: left;
}

input.form-text {
  font-size: 1.0em;
  border: none;
  padding: 10px 2.5%;
  height: 50px;
  margin-left: 0;
  width: 100%;
  overflow: hidden;
}

#SummonText:focus {
  outline: none;
}

.bento-record-image {
  float: left;
  margin: 0 10px 0 0;
  display: block;
  padding: 4px;
  background-color: $white;
  border: 1px solid $bento-record-image-border-color;
  border-radius: 0;
  max-width: 125px;
  transition: border 0.2s ease-in-out;
}


.bento-record-image a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.bento-record-publication {
  .bento-record-publication {
    font-style: italic;
    font-weight: 600;
  }
}

#bento-results-other {
  .bento-title {
    //padding-bottom: .8em;
    h2 {
      //font-size: 18px;
    }
  }
  ul.bento-other-resources {
    padding-left: 0;
    li {
      a {
        font-size: 18px;
        line-height: 1.55;
        font-weight: 300;
      }
      &:before {
        display: none;
      }
    }
  }
}

.gsc-control-cse {
  padding: 0 !important;
  .gcsc-branding {
    display: none;
  }
  .gsc-above-wrapper-area {
    display: none;
  }
  .gsc-result {
    .gs-title {
      height: 1.55em;
      text-decoration: none !important;
      a {
        font-family: 'Lato', sans-serif !important;
        font-size: 18px !important;
        line-height: 1.55 !important;
        font-weight: 400 !important;
        &:hover {
          text-decoration: underline;
        }
        b {
          font-family: 'Lato', sans-serif !important;
          font-size: 15px !important;
          line-height: 1.55 !important;
          text-decoration: none !important;
          font-weight: 500;
        }
      }
    }
    .gsc-url-top {
      font-family: 'Lato', sans-serif !important;
      font-size: 13px !important;
      line-height: 1.55 !important;
      font-weight: 400 !important;
    }
    .gsc-table-result {
      font-family: 'Lato', sans-serif !important;
      font-size: 13px !important;
      line-height: 1.55 !important;
      font-weight: 400 !important;
    }
  }
}

#bento-results-row-2 {
  background-color: #fafafa;
}
